import React from "react";
import theme from "theme";
import { Theme, Text, Image, Link, LinkBox, Box, Section, List, Em, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"works/ukitalt"} />
		<Helmet>
			<title>
				Кейс uKit Alt | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"uKit Alt — это дополнение к конструктору uKit, с помощью которого пользователи Facebook и VK могут сделать сайт из своей группы или профиля."} />
			<meta property={"og:title"} content={"Кейс uKit Alt | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"uKit Alt — это дополнение к конструктору uKit, с помощью которого пользователи Facebook и VK могут сделать сайт из своей группы или профиля."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header mix-blend-mode="difference" sm-padding="0px 0px 0px 0px">
				<Override slot="link" color="--darkL2" />
				<Override slot="link1" color="--darkL2" />
				<Override slot="link2" color="--darkL2" />
			</Components.Header>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						uKit Alt
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						uKit Alt is an add-on to the uKit builder that Facebook and VK users can use to make a website from their group or profile.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
						sm-height="auto"
						sm-margin="0px 0px 18px 0px"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="ukit.com/ru/lp/convert"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com/ru/lp/convert
							</Link>
						</LinkBox>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="https://ukit.com/ru/lp/convert-vk"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com/ru/lp/convert-vk
							</Link>
						</LinkBox>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="https://ukit.com/ru/lp/alt-vk"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com/ru/lp/alt-vk
							</Link>
						</LinkBox>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Work Completion Date: July 1, 2020
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Made in 240 days
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="33%"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
						sm-margin="0px 0px 0px 0px"
					>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UX / UI design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Product design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Design management
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="YoutubeVideo" margin="0 0 12px 0" inner-width="100%">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/6kpbtvJydHs"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase>
					GoAL
				</Components.BaseUppercase>
				<Text
					margin="18px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Make converting users' Facebook pages into websites on uKit builder.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="12px 0px 0px 0px"
				>
					The second step is to do the conversion of VK pages.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" sm-margin="0px 24px 16px 0px" margin="24px 24px 0px 0px">
					MY ROLE
				</Components.BaseUppercase>
				<Text
					margin="18px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
					min-height="auto"
					height="auto"
				>
					Here I was in the role of a sales manager and UX/UI designer.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					In the process, I came up with a step-by-step wizard, onboarding and mini-constructor interface. Then I adapted the existing constructor templates to the data from social networks.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					In twos with a fullstack programmer, we worked on an algorithm to retrieve and distribute pattern data.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Conducted usability testing, answered support questions, collected feedback and statistical reports.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					As the main person in charge of the project, I organized the work of other participants inside and outside of the core development team.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Description
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					uKit Alt is an add-on to the uKit website builder that allows you to create websites using data from user's Facebook and VK pages, which are fetched via API.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					The data can be used to create both personal and business websites. After selecting a page
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/7buu3fEcxqE"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					After creating the website versions, the user is taken to the wizard. Here he is offered to choose a design variant and type of site
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/7hVuMdmH8sg"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					After the visard, the user is taken to a mini-constructor where they can view the site, change its type, design and content.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					The transition into the mini-constructor is accompanied by onboarding with hints.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/YGk8A67Q9w8"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					The uKit Alt is a great way to bring new audiences to uKit and get a better unit economy.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Main page
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Make separate landing pages for Facebook and VK audience
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I came up with and designed adaptive landing page designs, recorded some explainer videos and designed examples of the websites created.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-landing-fb-ru-1.png?v=2022-06-18T06:24:17.156Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Facebook Audience Landing Page
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-landing-vk-another-ru-3.png?v=2022-06-18T06:24:33.384Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Alternative page for audience from VK
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-landing-vk-ru-2.png?v=2022-06-18T06:24:17.152Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Page for VK users
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Wizard of website creation
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Make a wizard that helps the user figure out how to transfer data from the page
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I designed a wizard that simply and clearly guides the user through the journey from page selection to the finished site.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					In places where some time is spent building the site, I've made entertaining animations and descriptions of the work that's going on at a given second.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					In a short time, the user is presented with six different variations of the site that they can scrutinize and scroll through.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-group-list-ru-1.png?v=2022-06-18T06:25:40.999Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Modal window for selecting a group or user profile
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-generation-ru-2.png?v=2022-06-18T06:25:52.844Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Process animation with description of the stages
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-select-design-ru-3.png?v=2022-06-18T06:26:00.567Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						View the resulting designs
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-select-type-ru-4.png?v=2022-06-18T06:26:07.373Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Choosing the type of website: multi-page or one-page
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Onboarding
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Before the user goes into the mini-constructor, you should familiarize the user with it and explain how to work here.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I recorded a video explanation and designed a step-by-step user onboarding with descriptions of each section of the mini-constructor.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					The user can hide onboarding cues at any time or show them again.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-onboarding-step1-ru-1.png?v=2022-06-18T06:26:55.331Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Introductory video explaining how to work in the mini-constructor
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-onboarding-step2-ru-2.png?v=2022-06-18T06:27:07.471Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Description of each section in special prompts
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-onboarding-step5-ru-3.png?v=2022-06-18T06:27:15.398Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						One last clue about the preview modes
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Mini-constructor
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					The uKit interface provides a lot of customization options, but this comes with a price in the form of a non-straightforward interface.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Before going into the uKit builder and creating a website, the user needs an easy way to customize the design and content.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I designed a simple mini-constructor that shows the user the resulting site and lets them customize it interactively.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					The entire setup is broken down into three sections:
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						type of site (single-page or multi-page)
					</Text>
					<Text margin="0px 0px 0px 0px">
						design
					</Text>
					<Text margin="0px 0px 0px 0px">
						content
					</Text>
				</List>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Playing with the settings user selects for himself the optimal variation of the site.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					To show how the site design adapts, I've added a device switcher.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-website-type-ru-1.png?v=2022-06-18T06:27:58.062Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						There is a panel on the left and a site on the right. In this section the user selects the type of site
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-design-ru-2.png?v=2022-06-18T06:28:05.879Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						This section selects the design of the site and its color palette
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-tablet-ru-4.png?v=2022-06-18T06:28:21.818Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The user can see how their site will look on a mobile device
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-content-phone-ru-3.png?v=2022-06-18T06:28:13.572Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						In the “Content” section, the user selects which blocks will be on the site
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Results
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					This way of creating websites in uKit still exists today and enjoys some popularity.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Importing data from social networks has been a good way to attract users from an area with low competition.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					REVIEWS
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em>
						“When people are interested in my T-shirts, they ask if I have a website where they can see all the work. I didn't have a website, only Facebook and Instagram - but not everyone is registered there. I decided that people should learn about my business without barriers, launched a converter, and for finalization I turned to a freelancer recommended by your service. And within a couple of days I had a ready website”.
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					<Strong>
						Konstantin
					</Strong>
					, designer of T-shirts and prints
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Link href="http://konstantinkoltin.ru/" target="_blank" color="--primary">
						konstantinkoltin.ru
					</Link>
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em>
						“We need a website for our image and a full-fledged presence in internet search. At first we thought of turning to freelancers, to agencies that are popular now - but we realized that we couldn't afford it now. Then we looked through a lot of constructors, found uKit, and decided to quickly turn a Facebook page, which builds up likes and popularity, into a mini-site where people can sign up to see us”
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					<Strong>
						Alexander
					</Strong>
					, co-owner of a consulting business
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						“Our old website didn't have a mobile version for smartphones and tablets. Paying for it separately was expensive. Moving the whole site to a new platform is also expensive. We found out that we could transfer our Facebook page to an adaptive website. Basically, we liked everything. And now we have a free mobile version.
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong>
						Tatiana
					</Strong>
					, owner of a bed linen and curtains salon
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					OPINIONS
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						“It's a good move – many entrepreneurs now start on social networks and then decide to make their own website. Integration with social networks is the future of website builders”
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					<Strong>
						Anatoly Denisov
					</Strong>
					, Editor-in-Chief of CMS Magazine
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em>
						“I tried it – interesting: it comes out simple but cute; easy to edit. For those who have a FB page, but can't get to the site – a great find”
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong>
						Anna Sokolova
					</Strong>
					, editor-in-chief of the Business section of The Village, ex-Hopes&Fears
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em>
						“I think for many small firms this is a good solution. You can see that uKit has tried to figure out what and where to distribute on the site”
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong>
						Victoria Virta
					</Strong>
					, founder of “Virta Internet Reservoir Academy”, IRI expert, ex-Rutube and ivi.
				</Text>
			</Section>
			<Components.PrevNextButtons>
				<Override slot="text">
					uKit
				</Override>
				<Override slot="text1">
					stepFORM
				</Override>
				<Override slot="linkBox" href="/works/ukit" />
				<Override slot="linkBox1" href="/works/stepform" />
			</Components.PrevNextButtons>
			<Components.Footer />
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});